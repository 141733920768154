
import Vue from 'vue';
import { PropType } from 'vue';
import commonPkg from '@point/classes';
import { SubAgency, Agency } from '@point/utility-classes';
import { Advertiser } from '../../store/modules/customer/types';
import utils from '../../util';
import analytics from '../../mixins/analytics';
import {
  Report,
  Time,
  DateRangeType,
  ReportProducts,
  FileType,
  Campaign,
} from '../../store/modules/scheduledreports/types';
import { throwServerError } from '@apollo/client/core';
export default Vue.extend({
  name: 'reportUI',
  props: {
    report: { type: Object as () => Report },
    contacts: { type: Array as PropType<string[]> },
    products: { type: Array as PropType<string[]> },
    campaigns: { type: Array as PropType<Campaign[]> },
  },
  components: {
    AdvertiserDetails: () => import('../components/toolbar/advertiser-selection/advertiserDetails.vue'),
  },
  mixins: [analytics],
  data: (): {
    additionalContacts: string[];
    addEmail: string;
    numbers: Array<number> | null;
    range: Array<string>;
    currentRange: Array<DateRangeType>;
    fileTypes: Array<FileType>;
    frequency: Array<string>;
    timeOfDay: Array<Time>;
    reportModel: Report | null;
    daysOfWeek: Array<string> | null;
    daysOfMonth: Array<number> | null;
    daysOfQuarter: Array<number> | null;
    valid: boolean;
    showValidationError: boolean;
    saved: boolean;
    debugPane: boolean;
    showCampaignSelection: boolean;
    campaignOption: string;
  } => ({
    additionalContacts: [],
    addEmail: '',
    daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    numbers: null,
    range: ['Day', 'Week', 'Month', 'Quarter'],
    currentRange: [
      { name: 'Week', value: 'Week' },
      { name: 'Month', value: 'Month' },
      { name: 'Quarter', value: 'Quarter' },
    ],
    frequency: ['Daily', 'Weekly', 'Monthly', 'Quarterly'],
    fileTypes: [
      { name: 'PDF', value: 'PDF' },
      { name: 'Excel', value: 'XLS' },
      { name: 'Business Review', value: 'PPT' },
    ],
    timeOfDay: [
      { name: '12 AM', value: 0 },
      { name: '1 AM', value: 1 },
      { name: '2 AM', value: 2 },
      { name: '3 AM', value: 3 },
      { name: '4 AM', value: 4 },
      { name: '5 AM', value: 5 },
      { name: '6 AM', value: 6 },
      { name: '7 AM', value: 7 },
      { name: '8 AM', value: 8 },
      { name: '9 AM', value: 9 },
      { name: '10 AM', value: 10 },
      { name: '11 AM', value: 11 },
      { name: '12 PM', value: 12 },
      { name: '1 PM', value: 13 },
      { name: '2 PM', value: 14 },
      { name: '3 PM', value: 15 },
      { name: '4 PM', value: 16 },
      { name: '5 PM', value: 17 },
      { name: '6 PM', value: 18 },
      { name: '7 PM', value: 19 },
      { name: '8 PM', value: 20 },
      { name: '9 PM', value: 21 },
      { name: '10 PM', value: 22 },
      { name: '11 PM', value: 23 },
    ],
    daysOfMonth: null,
    daysOfQuarter: null,
    reportModel: null,
    valid: true,
    showValidationError: false,
    saved: false,
    debugPane: false,
    showCampaignSelection: false,
    campaignOption: 'all',
  }),
  created(): void {
    this.numbers = this.fillListTo(92, 1);
    this.daysOfQuarter = this.fillListTo(92, 1);
    this.daysOfMonth = this.fillListTo(31, 1);
    this.reportModel = this.report;

    this.campaignOption = this.reportModel.Campaigns?.length > 0 ? 'some' : 'all';
    this.showCampaignSelection = this.reportModel.Campaigns?.length > 0;

    if (this.$route.query.advertiser) {
      this.reportModel.advertiser = this.$route.query.advertiser;
    }

    // for theming, compulse needs to be lowercase.
    if (this.reportModel.ParentAdvertiser.SubAgencyPartner === 'compulse')
      this.reportModel.ParentAdvertiser.SubAgencyPartner = 'Compulse';

    this.updateNumbers();
    this.updateRootDomain();

    if (this.isUpdate) {
      this.saved = true;
    }
  },
  methods: {
    addEmailToList(): void {
      if (this.addEmail.length < 2) {
        return;
      }
      if (
        utils.ValidationRules.email[0](this.addEmail) === true &&
        !this.additionalContacts.includes(this.addEmail) &&
        !this.reportModel.EmailTo.includes(this.addEmail)
      ) {
        this.additionalContacts.push(this.addEmail);
        this.reportModel.EmailTo.push(this.addEmail);
        this.addEmail = '';
        this.resetValidation();
      }
    },
    fillListTo(total: number, start: number): Array<number> {
      const list: Array<number> = [];
      while (start <= total) {
        list.push(start);
        start += 1;
      }
      return list;
    },
    selectAdvertiser(advertiser: Advertiser): void {
      this.$emit('clear-report');
      if (advertiser) {
        this.$emit('get-details', advertiser.PropertyId, true);
        // get advertiser product list
        this.$emit('get-campaigns', advertiser.PropertyId);
      }
    },
    selectAllProducts(): void {
      this.$nextTick(() => {
        if (this.allProductsSelected) {
          this.reportModel.Products = [];
        } else {
          // get list of just product values
          const allproducts: Array<string> = this.products.map((x: ReportProducts): string => x.ReportValue);
          this.reportModel.Products = allproducts.slice();
        }
        this.updateFileTypes();
      });
    },
    selectAllContacts(): void {
      this.$nextTick(() => {
        if (this.allContactsSelected) {
          this.reportModel.EmailTo = [];
        } else {
          this.reportModel.EmailTo = this.contacts.slice();
        }
      });
    },
    selectAllCampaigns(): void {
      this.reportModel.Campaigns = [];
      this.showCampaignSelection = false;

      this.analyticMRSelectCampaignsTrack(this.trackValue.EVENT_MR_CAMPAIGNS, 'ALL', this.parentName);
    },
    async saveReport(): Promise<void> {
      if (this.isMoreThanOneCampaignInPPT || this.isPPTSelectedForGA) {
        this.showValidationError = true;
        return;
      }
      if (this.$refs.reportForm.validate()) {
        this.reportModel.requestedBy = this.$store?.getters?.user?.email;
        // set local timezone (short: PDT, GTM, etc) using built in internationalization api.
        this.reportModel.Timezone = new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
          ?.formatToParts(new Date())
          ?.find(part => part.type === 'timeZoneName')?.value;
        // sending offset for now, will remove one of the values once the backend decides what they want.
        const today = new Date();
        this.reportModel.TimezoneOffset = today.getTimezoneOffset();
        // remove unnecessary keys
        this.checkFrequency();

        this.checkCampaigns();

        const subAgency = this.reportModel.ParentAdvertiser?.SubAgencyPartner;
        // for theming, compulse needs to be lowercase.
        if (subAgency?.toLowerCase() === 'compulse')
          this.reportModel.ParentAdvertiser.SubAgencyPartner = subAgency.toLowerCase();

        this.updateRootDomain();

        // save or update

        try {
          const saveLocation: string = this.isUpdate
            ? 'scheduledReports/updateReport'
            : 'scheduledReports/createReport';
          await this.$store.dispatch(saveLocation, this.reportModel).then((r: Report) => {
            if (r?.ErrorMessage || r?.error) {
              this.saved = false;
              this.$emit('error');
            } else {
              this.reportModel = r;
              this.saved = true;
              this.analyticSaveReportTrack(
                this.trackValue.EVENT_MANAGE_REPORTS,
                this.trackValue.TRACK_REPORT_SAVE,
                this.reportModel.Format,
                this.reportModel.IntervalType,
                this.reportModel.HourOfDay,
                this.parentName,
              );
              this.$emit('success', this.reportModel.Name);
            }
          });
        } catch (exp) {
          /* eslint-disable-next-line no-console */
          console.error(exp.message);
        }
      } else {
        this.showValidationError = true;
      }
    },
    resetAdvertiser(): void {
      this.$emit('reset-advertiser');
    },
    async saveReportAndCreateNew(): Promise<void> {
      await this.saveReport();
      this.resetAdvertiser();
    },
    cancel(): void {
      this.$emit('cancel');
    },
    resetValidation(): void {
      this.$refs.reportForm.resetValidation();
      this.showValidationError = false;
      this.saved = false;
    },
    changeTimeframe(): void {
      delete this.reportModel?.ReportDateRangeType;
      this.resetValidation();
    },
    checkFrequency(): void {
      this.analyticMRSelectFrequencyTrack(
        this.trackValue.EVENT_MR_FREQUENCY,
        this.reportModel?.IntervalType.toLowerCase() || 'daily',
        this.parentName,
      );
      // Remove frequency not related to chosen one.
      switch (this.reportModel?.IntervalType.toLowerCase()) {
        case 'daily':
          delete this.reportModel?.DayOfMonth;
          delete this.reportModel?.DayOfQuarter;
          delete this.reportModel?.DayOfWeek;
          break;
        case 'weekly':
          delete this.reportModel?.DayOfMonth;
          delete this.reportModel?.DayOfQuarter;
          break;
        case 'monthly':
          delete this.reportModel?.DayOfQuarter;
          delete this.reportModel?.DayOfWeek;
          break;
        case 'quarterly':
          // delete this.reportModel?.DayOfMonth;
          delete this.reportModel?.DayOfWeek;
          break;
        default:
          break;
      }
    },
    checkCampaigns(): void {
      if (!this.reportModel?.Campaigns) {
        this.reportModel.Campaigns = [];
      }
    },
    updateNumbers(value: string): void {
      const numberValue = value || this.reportModel?.ReportDateRangeType;
      if (value) this.reportModel.ReportDateWindow = 1;
      switch (numberValue) {
        case 'Day':
          this.numbers = this.fillListTo(92, 1);
          break;
        case 'Week':
          this.numbers = this.fillListTo(52, 1);
          break;
        case 'Month':
          this.numbers = this.fillListTo(12, 1);
          break;
        case 'Quarter':
          this.numbers = this.fillListTo(4, 1);
          break;
        default:
          break;
      }
    },
    adjustCampaignsAndFileTypes(): void {
      // in case user has changed product selection
      // and there are camps selected based on previous product selection
      if (this.reportModel.Campaigns?.length > 0) {
        this.reportModel.Campaigns = this.reportModel.Campaigns.filter((campId: string) => {
          const camp = this.campaigns.find((c: Campaign) => c.id === campId);
          return this.reportModel.Products.includes(camp.CampaignType);
        });
      }
      this.updateFileTypes();
    },
    updateFileTypes(): void {
      // DASH-4205 - remove or add PPT based on products
      const businessReviewFileType = this.fileTypes.find(type => type.value === 'PPT');
      if (this.reportModel.Products.includes('GA')) {
        if (businessReviewFileType) {
          this.fileTypes = this.fileTypes.filter(type => type.value !== 'PPT');
          if (this.reportModel.Format === 'PPT') Vue.set(this.reportModel, 'Format', null);
        }
      } else {
        if (!businessReviewFileType) {
          this.fileTypes.push({ name: 'Business Review', value: 'PPT' });
        }
      }
    },
    changedFormat(e): void {
      // scheduled PPT export is available only for 1 campaign
      if (e === 'PPT') {
        this.campaignOption = 'some';
        this.showCampaignSelection = true;
      }
    },
    updateRootDomain(): void {
      const lowHost = window.location.hostname.toLowerCase();
      const hostSegments = lowHost.split('.');
      if (hostSegments.includes('adportal')) {
        this.reportModel.RootDomain = lowHost;
      }
    },
    selectMRSomeCampaignsOption(): void {
      this.showCampaignSelection = true;
      const selectedResult = [];

      this.filteredCampaigns?.forEach(campaign => {
        if (this.reportModel.Campaigns?.includes(campaign.id)) {
          selectedResult.push(campaign.name);
        }
      });

      this.analyticMRSelectCampaignsTrack(
        this.trackValue.EVENT_MR_CAMPAIGNS,
        selectedResult?.join(',') || '',
        this.parentName,
      );
    },
    sendMRSelectedCampaignsTrack(): void {
      const selectedResult = [];

      this.filteredCampaigns?.forEach(campaign => {
        if (this.reportModel.Campaigns && this.reportModel.Campaigns.includes(campaign.id)) {
          selectedResult.push(campaign.name);
        }
      });

      this.analyticMRSelectCampaignsTrack(
        this.trackValue.EVENT_MR_CAMPAIGNS,
        selectedResult?.join(',') || '',
        this.parentName,
      );
    },
    selectFileType(): void {
      this.analyticMRSelectFileTypeTrack(
        this.trackValue.EVENT_MR_FILE_TYPE,
        this.reportModel?.Format || '',
        this.parentName,
      );
    },
    emailDuplicationRule(val): string | boolean {
      if (this.additionalContacts.includes(val) || this.reportModel.EmailTo.includes(val)) {
        return 'This email is already added';
      }

      return true;
    },
  },
  computed: {
    emailValidationRules(): Array<Function> {
      return utils.ValidationRules.email;
    },
    contactList(): Array<string> {
      return [...this.contacts, ...this.additionalContacts];
    },
    isDev() {
      const devEmails = [
        'lmosunova@upteam.com',
        'yoleshchuk@upteam.com',
        'etymchenko@upteam.com',
        'ikrasny@upteam.com',
      ];
      const email = this.$store.state?.customer?.user?.email;
      return devEmails.includes(email);
    },
    debugJSON() {
      // const someTimeAgo = new Date();
      // someTimeAgo.setMonth(today.getMonth() - 18);
      // "ReportStartDate": "${someTimeAgo.toLocaleDateString('en-US')}",
      return `{
  "Action": "ScheduledReport",
  "ReportStartDate": "2020-01-01",
  "ReportEndDate": "2024-01-01",
  "ScheduledReport": {
    "Campaigns": ${
      this.reportModel?.Campaigns?.length === 1
        ? `"${this.reportModel.Campaigns[0]}"`
        : JSON.stringify(this.reportModel?.Campaigns)
    },
    "Products": ${JSON.stringify(this.reportModel.Products)},
    "ReportDateRangeKind": "None",
    "PRDSC": "DLADVERTISER",
    "Format": "${this.reportModel.Format}",
    "PRPPID": "${this.reportModel.ParentAdvertiser.PropertyId}",
    "AgencyPartner": "${this.Theme.companyId}",
    "RootDomain": "${this.reportModel.RootDomain || ''}",
    "CreatedBy": "${this.$store.getters.user.email}"
  }
}`;
    },
    debugExportApi(): string {
      if (this.$route.query?.exportVersion) {
        return `/api/exportReport?exportVersion=${this.$route.query?.exportVersion}`;
      }
      return '/api/exportReport';
    },
    subAgencies(): Array<SubAgency> | null {
      if (!this.report?.ParentAdvertiser?.AgencyPartner) return null;
      const agencyGroupings: Array<Agency> = commonPkg.agencies;

      const agencyGroup = agencyGroupings.find(
        (group: Agency) => group.id === this.report.ParentAdvertiser.AgencyPartner,
      );
      if (!agencyGroup) return null;

      return agencyGroup.subAgencies;
    },
    intervalType(): string | null {
      const model: Report = this.reportModel;
      if (!model.IntervalType) return null;
      return model.IntervalType.toLowerCase();
    },
    hasAdvertiser(): boolean {
      const report: Report = this.report;
      return !!(
        report?.ParentAdvertiser?.PropertyId &&
        report?.ParentAdvertiser?.PropertyId !== null &&
        this.products.length > 0 &&
        this.contacts.length > 0
      );
    },
    isMoreThanOneCampaignInPPT(): boolean {
      return (
        this.reportModel.Format === 'PPT' && (this.campaignOption === 'all' || this.reportModel.Campaigns?.length > 1)
      );
    },
    isPPTSelectedForGA(): boolean {
      return this.reportModel.Format === 'PPT' && this.reportModel.Products.includes('GA');
    },
    isUpdate(): boolean {
      return this.reportModel?.PropertyId?.length > 0 || this.$route.query?.reportId?.length > 1;
    },
    allProductsSelected(): boolean {
      if (!this.reportModel.Products) return false;
      return this.reportModel.Products.length === this.products.length;
    },
    someProductsSelected(): boolean {
      if (!this.reportModel.Products) return false;
      return this.reportModel.Products.length > 0 && !this.allProductsSelected;
    },
    selectAllIcon(): string {
      if (this.allProductsSelected) return 'check_box';
      if (this.someProductsSelected) return 'check_box_outline_blank';
      return 'check_box_outline_blank';
    },
    selectAllContactIcon(): string {
      if (this.allContactsSelected) return 'check_box';
      if (this.someContactsSelected) return 'check_box_outline_blank';
      return 'check_box_outline_blank';
    },
    allContactsSelected(): boolean {
      if (!this.reportModel.EmailTo) return false;
      return this.reportModel.EmailTo.length === this.contacts.length;
    },
    someContactsSelected(): boolean {
      if (!this.reportModel.EmailTo) return false;
      return this.reportModel.EmailTo.length > 0 && !this.allContactsSelected;
    },
    selectAllContactsIcon(): string {
      if (this.allContactsSelected) return 'check_box';
      if (this.someContactsSelected) return 'check_box_outline_blank';
      return 'check_box_outline_blank';
    },
    loading(): boolean {
      return (
        this.$store.state?.scheduledReports?.loading ||
        this.$store.state?.advertiser?.isAdvertiserInfoLoading ||
        this.$store.getters?.networkActivity
      );
    },
    buttonText(): string {
      if (this.showValidationError) return 'Missing required fields';
      return this.isUpdate ? 'Save' : 'Schedule';
    },
    saveAndCreateNewButtonText(): string {
      if (this.showValidationError) return 'Missing required fields';
      return this.isUpdate ? 'Save and Create New' : 'Schedule and Create New';
    },
    parentName(): string {
      return this.report?.ParentAdvertiser?.Name ? this.report.ParentAdvertiser.Name : null;
    },
    isSinclair(): boolean {
      return this.report?.ParentAdvertiser?.AgencyPartner === 'Sinclair';
    },
    filteredCampaigns(): Array<Campaign> {
      let filtered: Array<Campaign> = this.campaigns;
      if (this.reportModel.Products.length) {
        filtered = this.campaigns.filter((camp: Campaign) => {
          return this.reportModel.Products.includes(camp.CampaignType);
        });
      }
      return filtered;
    },
  },
  watch: {
    report: {
      handler: function (report) {
        // set parent advertiser data
        this.reportModel.ParentAdvertiser = report.ParentAdvertiser;
        this.reportModel.Name = report.Name;
        this.reportModel.Campaigns = report.Campaigns;
        this.reportModel.EmailTo = report.EmailTo;
      },
    },
  },
});
